<template>
  <slideout
    dock="right"
    :size="mainPanelSize"
    :min-size="nested ? 400 : 900"
    :allow-resize="nested ? false : true"
    :append-to="nested ? '' : '#app'"
    :class="readonly ? 'slideout-readonly-content' : ''"
    :visible.sync="slideouts.update.active"
    v-on:close="onSlideoutClosing"
  >
    <template v-slot:header>
      <h3 v-if="isFetching" class="font-weight-bold pa-1" small>
        <i class="fad fa-spinner-third fa-spin mr-2"></i> Fetching User...
      </h3>
      <h3 v-else-if="selected.id != null" class="font-weight-bold pa-1" small>
        <span v-if="readonly">
          <i class="fad fa-user-tie mr-2"></i> {{ selected.firstName }}
          {{ selected.lastName }}
        </span>
        <span v-else>
          <i class="fad fa-pen-square mr-2"></i> Update: '{{ selected.firstName }}
          {{ selected.lastName }}'
        </span>
        <entity-id class="ml-2" :label="selected.id" :path="userUrl" title="User"></entity-id>
      </h3>
      <h3 v-else class="font-weight-bold pa-1" small>
        <i class="fad fa-plus-square mr-2"></i> Create a User
      </h3>
      <div>
        <code
          v-if="readonly"
          class="text-caption ml-2 white secondary--text mr-2"
          style="border: 1px dashed rgba(42, 54, 59, 0.28) !important"
        >
          <span class="fa-stack mr-0">
            <i class="fad fa-pencil fa-stack-1x"></i>
            <i class="fas fa-slash fa-stack-1x" style="color: Tomato"></i>
          </span>
          Read-Only
        </code>
        <panel-size-control v-model="slideouts.update.fullWidth"></panel-size-control>
        <v-btn @click="closeUpdateSlideout()" icon><i class="far fa-times"></i></v-btn>
      </div>
    </template>

    <v-container fluid class="pa-0 text-center" v-if="isFetching">
      <video width="320" muted loop autoplay style="padding: 0.5rem; margin: 0 auto">
        <source src="/img/art/astronaut-loves-music-4980476-4153140.mp4" type="video/mp4" />
      </video>
      <p class="font-weight-bold">Fetching User ...</p>
    </v-container>
    <v-container fluid class="" v-else>
      <v-form v-model="slideouts.update.valid" ref="updateForm">
        <v-row class="my-0" dense>
          <v-col cols="12" md="12" class="mb-2">
            <single-image-uploader
              id="add-user-uploader"
              :apiUrl="imageApiUrl"
              v-model="selected.avatarUrl"
              :disabled="readonly"
            ></single-image-uploader>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field-alt
              :rules="[allRules.required, allRules.length(2), allRules.noWhiteSpaces]"
              label="First Name"
              id="userName"
              ref="userName"
              placeholder="first name"
              v-model="selected.firstName"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field-alt
              :rules="[allRules.required, allRules.length(2), allRules.noWhiteSpaces]"
              label="Last Name"
              id="userLastName"
              ref="userLastName"
              placeholder="last name"
              v-model="selected.lastName"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field-alt
              :rules="allRules.emailRules"
              label="Email"
              id="userEmail"
              ref="userEmail"
              placeholder="Email"
              v-model="selected.email"
              :readonly="readonly"
            >
            </v-text-field-alt>
          </v-col>
          <v-col cols="12" md="6">
            <label class="input-label mb-1">
              Phone Number
              <!-- <i class="fas fa-star-of-life pink--text label-icon"></i> -->
            </label>
            <maz-phone-number-input
              clearable
              :class="{ 'hide-clear-btn': readonly }"
              ref="userPhone"
              placeholder="Contact Phone"
              color="primary"
              v-model="selected.phoneNumber"
              :disabled="readonly"
              default-country-code="US"
              size="sm"
              :required="false"
            >
            </maz-phone-number-input>
          </v-col>
          <v-col cols="12" md="6">
            <roles-selector v-model="selected.roleId" :readonly="readonly"></roles-selector>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <template v-slot:footer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div
          class="d-flex ml-2"
          v-if="!isFetching && readonly && isMainRoute && $has(perms.DNAUsers.Update)"
        >
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="switchToEditMode()"
                :disabled="isFetching"
                color="orange"
              >
                <i class="fas fa-pen mr-2" style="font-size: 10px"></i>Edit
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >Edit<kbd class="light ml-2 fs-12px">CTRL<span class="opacity-54">+</span>E</kbd>
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="slideouts.update.isLoading"
                @click="jumpToRequiredField()"
                color="error"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                class="ml-2"
                outlined
              >
                <i class="fas fa-asterisk fs-14px"></i>
              </v-btn>
            </template>
            <span>Jump to Required Field</span>
          </v-tooltip>
        </div>

        <v-btn
          class="ml-2"
          v-if="!isFetching && !readonly && selected.id != null"
          :disabled="slideouts.update.isLoading || !slideouts.update.hasChanges"
          @click="discardChanges()"
          color="secondary"
        >
          <i class="fal fa-clock-rotate-left mr-2"></i> Discard
        </v-btn>

        <div class="d-flex ml-2" v-if="!isFetching && !readonly && $has(perms.DNAUsers.View)">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="info"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(false)"
                :loading="slideouts.update.isLoading"
              >
                <i class="mr-2" :class="selected.id ? 'fas fa-save' : 'fal fa-plus'"></i>
                {{ selected.id ? "Save" : "Create" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save" : "Create"
              }}<kbd class="light ml-1 fs-12px">CTRL<span class="opacity-54">+</span>S</kbd>
            </span>
          </v-tooltip>
        </div>
        <div class="d-flex ml-2" v-if="!isFetching && !readonly && $has(perms.DNAUsers.Update)">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="cyan white--text"
                :disabled="
                  !slideouts.update.valid ||
                  slideouts.update.isLoading ||
                  !slideouts.update.hasChanges
                "
                @click="updateConfirmed(true)"
                :loading="slideouts.update.isLoading"
              >
                <i class="fas fa-save mr-2"></i>
                {{ selected.id ? "Save & Close" : "Create & Close" }}
              </v-btn>
            </template>
            <span class="d-flex align-center"
              >{{ selected.id ? "Save & Close" : "Create & Close" }} Panel<kbd
                class="light ml-2 fs-12px"
                >CTRL<span class="opacity-54">+</span>SHIFT<span class="opacity-54">+</span>S</kbd
              >
            </span>
          </v-tooltip>
        </div>

        <div class="d-flex ml-2" v-if="selected.id != null">
          <v-tooltip top z-index="999" nudge-top="-4px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="2"
                width="32px"
                height="32px"
                min-width="32px"
                min-height="32px"
                :loading="isFetching"
                :disabled="slideouts.update.isLoading || isFetching || slideouts.update.hasChanges"
                @click="fetchUser()"
              >
                <i class="fal fa-arrows-rotate" style="font-size: 16px"></i>
              </v-btn>
            </template>
            <span>Refetch User</span>
          </v-tooltip>
        </div>
        <v-menu dense offset-y top left z-index="1000" v-if="selected.id != null">
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              :disabled="isFetching || slideouts.update.isLoading || slideouts.update.hasChanges"
              :elevation="2"
              width="32px"
              height="32px"
              min-width="32px"
              min-height="32px"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              <i class="far fa-ellipsis-v" style="font-size: 16px"></i>
            </v-btn>
          </template>
          <v-list class="more-options-menu">
            <v-list-item
              @click="openLaborRates()"
              v-if="$has(perms.DNAUsers.ViewDefaultLaborRates)"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="green--text text--darken-2">fas fa-circle-dollar</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="green--text text--darken-3">
                  Default Labor Rates
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="loggedUser.isSuperUser && !selected.isSuperUser && $has(perms.DNAUsers.Update)"
              @click="makeSuperUser"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="amber--text text--darken-2">fas fa-crown</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="amber--text text--darken-3">
                  Make as Super User
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-else-if="
                loggedUser.isSuperUser && selected.isSuperUser && $has(perms.DNAUsers.Update)
              "
              @click="unmakeSuperUser"
            >
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="red--text">fas fa-crown</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="red--text">Remove as Super User</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider class="my-2" v-if="loggedUser.isSuperUser"></v-divider>

            <v-list-item @click="suspend" v-if="$has(perms.DNAUsers.Update)">
              <v-list-item-icon class="mr-2 justify-center">
                <v-icon small class="secondary--text">{{
                  selected.isSuspended ? "far fa-check" : "far fa-ban"
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{
                  selected.isSuspended ? "Activate" : "Suspend"
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
      <user-labor-rates ref="userLaborRates" nested></user-labor-rates>
    </template>
  </slideout>
</template>

<script>
import ApiService from "../services/StaffService";
import perms from "../../../plugins/permissions";
import RolesSelector from "../../Shared/components/RolesSelector.vue";

import MazPhoneNumberInput from "maz-ui/lib/maz-phone-number-input";
import "maz-ui/lib/css/base.css";
import "maz-ui/lib/css/maz-phone-number-input.css";
import PanelSizeControl from "../../Shared/components/PanelSizeControl.vue";
import UserLaborRates from "./UserLaborRates.vue";

export default {
  components: {
    RolesSelector,
    MazPhoneNumberInput,
    PanelSizeControl,
    UserLaborRates,
  },
  name: "edit-user",
  data() {
    return {
      perms,
      imageApiUrl: `account/Image`,
      validPhone: false,
      userId: null,
      selected: {},
      readonly: false,
      isFetching: false,
      selectedCemented: {},
      slideouts: {
        update: {
          active: false,
          fullWidth: false,
          valid: false,
          isLoading: false,
          isClosing: false,
          hasChanges: false,
        },
      },
    };
  },
  props: {
    nested: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    loggedUser() {
      return this.$store.getters.user;
    },
    userUrl() {
      if (this.selected == null) return "";
      return `users/${this.selected.id}`;
    },
    isMainRoute() {
      if (this.$route.name == "single-user") return true;
      else return false;
    },
    mainPanelSize() {
      return this.slideouts.update.fullWidth ? "100%" : this.nested ? "87%" : "900px";
    },
  },
  mounted() {
    document.addEventListener("keydown", this.documentKeyListener);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.documentKeyListener);
  },
  methods: {
    openLaborRates() {
      this.$refs.userLaborRates.open(this.selected.id, false);
    },
    documentKeyListener(event) {
      this.ctrlKeyActive = event.ctrlKey;
      this.shiftKeyActive = event.shiftKey;
      if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(false);
      } else if (
        this.ctrlKeyActive &&
        this.shiftKeyActive &&
        event.code == "KeyS" &&
        this.slideouts.update.valid &&
        !this.slideouts.update.isLoading &&
        this.slideouts.update.hasChanges
      ) {
        event.preventDefault();
        this.updateConfirmed(true);
      } else if (
        this.ctrlKeyActive &&
        !this.shiftKeyActive &&
        event.code == "KeyE" &&
        !this.isFetching &&
        this.readonly &&
        this.isMainRoute &&
        this.$has(this.perms.Projects.Update)
      ) {
        event.preventDefault();
        this.switchToEditMode(true);
      }
    },
    onPhoneUpdate(val) {
      this.validPhone = val.isValid;
    },
    switchToEditMode() {
      this.readonly = false;
    },
    onSlideoutClosing(e) {
      this.$log("onSlideoutClosing, active:", this.slideouts.update.active);
      // prevent close and wait
      e.wait = true;

      if (this.slideouts.update.hasChanges && !this.$confirmReleaseChanges()) {
        // allow close
        e.close = false;
        return;
      }

      //reset the changes
      this.selected = {};
      this.cementUser();
      this.$releaseChanges();

      //allow close, and un wait
      e.wait = false;
      e.close = true;
      this.$emit("close");
    },
    announceChange(isNewUser) {
      this.$emit("save", this.selected, isNewUser);
    },
    fetchUser() {
      this.isFetching = true;
      ApiService.getUserById(this.userId)
        .then((resp) => {
          this.$log("getTemplateById >> success", resp.data);
          this.isFetching = false;
          this.slideouts.update.isLoading = false;
          this.selected = this.cloneDeep(resp.data);
          this.cementUser();
          if (this.isMainRoute)
            document.title =
              `${this.selected.firstName} ${this.selected.lastName}` + " | Concordia";
          this.handleFocus();
        })
        .catch((err) => {
          this.$log("getTemplateById >> error", err);
          this.closeUpdateSlideout();
          this.$handleError(err);
        });
    },
    updateConfirmed(closeAfter) {
      this.slideouts.update.isLoading = true;
      let toSend = JSON.parse(JSON.stringify({ ...this.selected }));
      this.$log("update >> toSend", toSend);
      ApiService.edit(toSend)
        .then((resp) => {
          this.slideouts.update.isLoading = false;
          var message = "User updated successfully!";
          this.$log(">>> updated", resp.data);
          this.selected = resp.data;
          this.cementUser();
          var isNewUser = false;
          if (!toSend.id) {
            isNewUser = true;
            message = "User added successfully!";
          }
          this.announceChange(isNewUser);
          this.$dialog.notify.success(message, {
            position: "top-right",
            timeout: 3000,
          });
          this.readonly = true;
          this.checkForChanges();
          if (closeAfter) {
            this.closeUpdateSlideout();
          }
        })
        .catch((err) => {
          this.slideouts.update.isLoading = false;
          this.$handleError(err);
        });
    },
    cementUser() {
      this.selectedCemented = this.cloneDeep(this.selected);
      this.checkForChanges();
    },
    closeUpdateSlideout() {
      this.slideouts.update.active = false;
    },
    handleFocus() {
      if (this.isFetching) return;
    },
    open(id, editMode = false) {
      if (id == null) {
        //new user
        this.selected = {
          name: null,
        };
        this.readonly = false;
        this.isFetching = false;
        this.userId = null;
        this.$refs.updateForm.resetValidation();
        this.handleFocus();
        this.cementUser();
      } else {
        this.userId = id;
        this.readonly = !editMode;
        this.fetchUser();
      }
      this.slideouts.update.active = true;
      // this.cementUser();
    },
    checkForChanges() {
      if (!this.slideouts.update.active) return;

      this.slideouts.update.hasChanges = !this.isEqual(this.selected, this.selectedCemented);
      this.$log("#### checkForChanges:", this.slideouts.update.hasChanges);

      if (this.slideouts.update.hasChanges) this.$guardChanges();
      else this.$releaseChanges();
    },
    discardChanges() {
      this.selected = this.cloneDeep(this.selectedCemented);
    },
    jumpToRequiredField() {
      if (this.findRequiredFieldInFrom(this.$refs.updateForm, () => {})) return;
      // if (!this.validPhone) {
      //   this.$log("this.$refs.userPhone", this.$refs.userPhone);
      //   this.$refs.userPhone.focusPhoneNumberInput();
      //   return;
      // }

      this.$dialog.notify.info("All required fields are filled!", {
        position: "top-right",
        timeout: 3000,
      });
    },
    suspend() {
      this.$dialog
        .warning({
          text: selected.isSuspended
            ? "Are you sure you want to active this user?"
            : `Are you sure you want to suspend this user?`,
          title: selected.isSuspended ? "Active User" : `Suspend User?`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ApiService.suspend(selected.id, !selected.isSuspended)
                  .then((resp) => {
                    this.$emit("save", resp.data);
                    this.$dialog.notify.success(
                      `User ${selected.isSuspended ? "active" : "suspend"} successfully`,
                      {
                        position: "top-right",
                        timeout: 3000,
                      }
                    );
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    makeSuperUser() {
      this.$dialog
        .warning({
          text: `Are you sure you want to make <b><u>${selected.firstName} ${selected.lastName}</u></b> as a Super User?`,
          title: `Make as Super User!`,
          color: "warning",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "warning",
              handle: () => {
                return ApiService.updateSuperUser(selected.id, true)
                  .then((resp) => {
                    this.$emit("save", resp.data);
                    this.$dialog.notify.success(
                      `<b><u>${selected.firstName} ${selected.lastName}</u></b> is now a Super User!`,
                      {
                        position: "top-right",
                        timeout: 5000,
                      }
                    );
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
    unmakeSuperUser() {
      this.$dialog
        .error({
          text: `Do you want to remove <b><u>${selected.firstName} ${selected.lastName}</u></b> from being a Super User?`,
          title: `Remove as Super User!`,
          color: "error",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Confirm",
              color: "error",
              handle: () => {
                return ApiService.updateSuperUser(selected.id, false)
                  .then((resp) => {
                    this.$emit("save", resp.data);
                    this.$dialog.notify.success(
                      `<b><u>${selected.firstName} ${selected.lastName}</u></b> is no longer a Super User!`,
                      {
                        position: "top-right",
                        timeout: 5000,
                      }
                    );
                  })
                  .catch((err) => {
                    this.$handleError(err);
                  });
              },
            },
          },
        })
        .then((res) => {});
    },
  },
  watch: {
    selected: {
      handler() {
        this.checkForChanges();
      },
      deep: true,
    },
  },
};
</script>
