<template>
  <v-menu dense offset-y left bottom style="z-index: 999">
    <template v-slot:activator="{ attrs: attrsMenu, on: onMenu }">
      <v-tooltip bottom nudge-bottom="-5">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-btn height="32" color="white" small v-bind="attrsMenu" v-on="onMenu">
              <i class="fad fa-file-spreadsheet" style="font-size: 16px"></i>
            </v-btn>
          </div>
        </template>
        <span>Download Excel Reports</span>
      </v-tooltip>
    </template>
    <v-list class="more-options-menu">
      <v-list-item @click="generateList">
        <v-list-item-icon class="mr-2 justify-center">
          <v-icon small class="secondary--text">far fa-list</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="secondary--text">Generate Users List</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item @click="generateLaborRates">
        <v-list-item-icon class="mr-2 justify-center">
          <v-icon small class="secondary--text">fas fa-circle-dollar</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="secondary--text"
            >Generate Users Labor Rates List</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import Enums from "../../../plugins/enums";
import { eventBus } from "../../../main";
import StaffService from "../services/StaffService";

export default {
  name: "export-excel",
  data() {
    return {
      Enums: Enums,
      isLoading: false,
    };
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    generateList() {
      this.$dialog
        .info({
          title: `Generate excel report?`,
          text: `Generate excel report from this Users list?`,
          color: "info",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Generate",
              color: "info",
              handle: () => {
                this.generateConfirmed(false);
              },
            },
          },
        })
        .then((res) => {});
    },
    generateLaborRates() {
      this.$dialog
        .info({
          title: `Generate excel report?`,
          text: `Generate excel report from this User Rates list?`,
          color: "info",
          persistent: true,
          actions: {
            false: {
              text: "Cancel",
            },
            true: {
              text: "Generate",
              color: "info",
              handle: () => {
                this.generateConfirmed(true);
              },
            },
          },
        })
        .then((res) => {});
    },
    generateConfirmed(isExportingLaborRates) {
      this.isLoading = true;
      this.$log("generateConfirmed >> this.options", this.options);
      const optionsToSend = this.$clean(this.options, true);
      if (isExportingLaborRates)
        StaffService.exportLaborRatesExcel(optionsToSend).then(this.onResponse).catch(this.onCatch);
      else StaffService.exportExcel(optionsToSend).then(this.onResponse).catch(this.onCatch);
    },
    onResponse(resp) {
      this.$log("report generate resp.data", resp.data);
      this.$notify(resp.data, "info");
      eventBus.$emit("add-to-notification-center", resp.data);
      this.discardGenerate();
    },
    onCatch(err) {
      this.$log(">>> StaffService export", err);
      this.isLoading = false;
      this.$dialog.notify.error(err, {
        position: "top-right",
        timeout: 3000,
      });
    },
    discardGenerate() {
      this.isLoading = false;
    },
    onSlideoutClosing() {
      this.discardGenerate();
    },
  },
  computed: {},
  watch: {},
  components: {},
};
</script>

<style lang="scss"></style>
